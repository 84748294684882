import $ from 'jquery'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

const $doc = $(document)

// Simple Fade In animation
function animateSimple(elem) {
  gsap.to(elem, {
    duration: 0.5,
    y: 0,
    autoAlpha: 1,
    ease: "power1.out",
    stagger: 0.2,
    delay: 0.3,
    overwrite: 'auto'
  })
}

// Simple Fade In with more delay for first elem on the page (Hero)
function animateFirstElem(elem) {
  gsap.to(elem, {
    duration: 0.8,
    y: 0,
    autoAlpha: 1,
    ease: "power2.inOut",
    stagger: 0.5,
    delay: 0.8,
    overwrite: 'auto'
  })
}

// Set-ups for Animations
function setupFadeInElement(elem) {
  hide(elem) // assure that the element is hidden when scrolled into view
  const anim = gsap.to(elem, {duration: 1, autoAlpha: 1, y: 0, delay: 0.3, paused: true});
  ScrollTrigger.create({
    trigger: elem,
    end: "top 100px",
    once: true,
    onEnter: self => {
      // If it's scrolled past, set the state
      // If it's scrolled to, play it
      self.progress === 1 ? anim.progress(1) : anim.play()
    },
  });
}
function setupFadeInFirstElement(elem) {
  hide(elem) // assure that the element is hidden when scrolled into view
  ScrollTrigger.create({
    trigger: elem,
    onEnter: function() { animateSimple(elem) }
  })
}
function setupFadeInStaggerElements() {
  gsap.utils.toArray(".js-fadeinStagger").forEach((elem, i) => {
    gsap.set(elem, {opacity:0})
    gsap.from(elem, {
      y: 10,
      ease: "power2.inOut",
      scrollTrigger: {
        trigger: elem,
        start: "top 100%"
      }
    });
  });
  ScrollTrigger.batch(".js-fadeinStagger", {
    onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, ease: "power2.inOut",stagger: {each: 0.15, grid: [1, 3]}, overwrite: true}),
    onLeaveBack: batch => gsap.set(batch, {opacity: 0, y: 10, overwrite: true})
  });
  ScrollTrigger.addEventListener("refreshInit", () => gsap.set(".js-fadeinStagger", {y: 0}),{passive:true});
}

function hide(elem) {
  gsap.set(elem, {autoAlpha: 0, y: 0})
}

function setupWheelAnimation(elem) {
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".js-wheel-animation",
      start: "top center",
      toggleActions: "play none reverse none"
    }
  });
  tl.set("#color-wheel", {scale: 0, rotation:0, transformOrigin:"center", duration: 0.1})
    .set("#white-text", {autoAlpha: 0, duration: 0.1})
    .set("#inner-circle", {scale: 0, autoAlpha: 0, transformOrigin:"center", duration: 0.1})
    .to("#color-wheel", {scale: 1, rotation:360, transformOrigin:"center", duration: 1.5, ease:"power1.out", delay: 0.3})
    .to("#white-text", {autoAlpha: 1, duration: .5, ease:"power1.out"}, "-=0.2")
    .to("#inner-circle", {scale: 1, autoAlpha: 1, duration: .7, transformOrigin:"center", ease:"power1.out"}, "-=0.7"); 
}

function init(){
  gsap.registerPlugin(ScrollTrigger)
  gsap.utils.toArray(".js-fadeinHero").forEach( setupFadeInFirstElement )
  gsap.utils.toArray(".js-fadein").forEach( setupFadeInElement )
  gsap.utils.toArray(".js-wheel-animation").forEach( setupWheelAnimation )
  setupFadeInStaggerElements()

  $doc.on("click",".js-color-elem",function(event) {
    event.preventDefault();
    var color = $(this).data('color');
    $('.js-color-elem-hover').removeClass('is-active');
    $('#color-wheel-hover').removeClass('is-active');
    if($('.js-color-elem-hover').hasClass(color)){
      const hoverElement = document.querySelectorAll('.js-color-elem-hover.'+color);
      $('#color-wheel-hover').addClass('is-active');
      $(hoverElement).addClass('is-active');
    }
  });
  $doc.on("click","#color-wheel-hover",function(event) {
    event.preventDefault();
    $('.js-color-elem-hover').removeClass('is-active');
    $('#color-wheel-hover').removeClass('is-active');
  });
  $doc.on('mouseenter', ".js-color-elem", function(event){ 
    var color = $(this).data('color');
    $('.js-color-elem-hover').removeClass('is-active');
    $('#color-wheel-hover').removeClass('is-active');
    if($('.js-color-elem-hover').hasClass(color)){
      const hoverElement = document.querySelectorAll('.js-color-elem-hover.'+color);
      $('#color-wheel-hover').addClass('is-active');
      $(hoverElement).addClass('is-active');
    }
  });
  $doc.on('mouseleave', ".js-color-elem", function(event){ 
    $('.js-color-elem-hover').removeClass('is-active');
    $('#color-wheel-hover').removeClass('is-active');
  });
}

export default function initGreenSock(){
  $(document).ready( init )
}
