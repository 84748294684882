import $ from 'jquery'
import magnificPopup from 'magnific-popup'
const  $doc = $(document)

// this is just an example of functionality, opens only one static popup with #popup ID
function openModal(e) {
  const targetModal = e.target.dataset.targetModal;

  $.magnificPopup.open({
    items: {
      src: `#${targetModal}`,
      type: 'inline',
      mainClass: 'popup--animate',
    },
    closeBtnInside: true
  });
}

export default function initMagnificPopup(){
  $doc.on('click', '.js-open-modal', openModal );
  $('.js-video-external-popup').magnificPopup({
    type: 'iframe',
    mainClass: 'popup--animate',
    closeBtnInside: true
  });
  $('.js-video-mp4-popup').magnificPopup({
    type: 'inline',
    midClick: true,
    mainClass: 'popup--animate',
    closeBtnInside: true,
    callbacks: {
      open: function() {
        let mp = $.magnificPopup.instance;
        let t = $(mp.currItem.el[0]);
        let vid = t.attr('href');
        $(vid).find('video').trigger('play');
      },
      close: function () {
        let mp = $.magnificPopup.instance;
        let t = $(mp.currItem.el[0]);
        let vid = t.attr('href');
        $(vid).find('video').trigger('pause');
      }
    }
  });
}
