import $ from 'jquery'

const $doc = $(document)
let state = {
  curr_page: 1,
  category: 'all',
  letter: 'all',
  perPage: 12 // standardized posts_per_page count to reliably use paging
}

export default function initStaffFilters(){
  var staffAjax = function() {
    const category = $('.js-radio-staff.is-active').attr('id');
    const letter = $('.js-alpha-filter.is-active').attr('id');
    const newQuery = state.category != category || state.letter != letter
    const curr_page = newQuery ? 1 : state.curr_page + 1

    state.category = category
    state.letter = letter
    state.curr_page = curr_page

    if (!newQuery && $('#staff-list .js-single-staff').length < state.perPage) return false

    $.ajax({
      url: mangroveAjax.adminUrl,
      type:'POST',
      data: {
        category,
        letter,
        curr_page,
        action:'staff_ajax'
      },
      beforeSend:function(xhr){
        curr_page == 1 && $('#staff-list').html('')
        $('.js-loader').show();
        $('#loadmore-staff').hide();
      },
      success:function(data){
        $('.js-loader').hide();
        var $data = $(data);
        
        if ($data.length) {
          $('#staff-list').append($data);
          $data = $data.filter( function() { return this.nodeName == 'LI' } )
          if($data.length < state.perPage || $data.length === -1) {
            $('#loadmore-staff').hide();
          } else {
            $('#loadmore-staff').show();
          }
        } else {
          $('#staff-list').html('<h2>No staff members matching search criteria found!</h2>');
          $('#loadmore-staff').hide();
        }
      }
    });
    return false;
  }

  var $staffMember = $('#staff-list .js-single-staff');

  var $btns = $('.letter').click(function() {
    var id = this.id;

    // if (id == 'all') {
    //   $staffMember.fadeIn(450);
    // } else {
    //   $('#staff-list').html('')
    //   staffAjax('-1',id)
    // }

    $btns.removeClass('is-active');
    $(this).addClass('is-active');
    staffAjax()
  })

  $doc.on('submit', '.js-filter-selected', function(event) {
    $(this).toggleClass('is-active');
    $('.js-filter-dropdown').toggleClass('is-active');
  })

  $doc.on('click', '.js-filter-selected', function(event) {
    $(this).toggleClass('is-active');
    $('.js-filter-dropdown').toggleClass('is-active');
  })

  $doc.on('click', '.js-radio-staff', function(event) {
    $('.js-radio-staff').removeClass('is-active')
    $(this).addClass('is-active')
    var catName = $(this).data('name')
    $('.filter__department-selected').text(catName)
    $('.js-filter-dropdown').toggleClass('is-active')
    $('.js-filter-selected').toggleClass('is-active')

    staffAjax()
  })

  $doc.on("click","#loadmore-staff .button",function(event) {
    staffAjax()
  });
}
