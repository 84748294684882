import $ from 'jquery'
import magnificPopup from 'magnific-popup'

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function openCenterPopup() {
  $.magnificPopup.open({
    items: {
      src: '#center-popup',
      type: 'inline',
      mainClass: 'popup--animate',
    },
    closeBtnInside: true
  });
}

function init(){
  var hasCookie = getCookie('privacy-cookies');
  var hasPopupCookie = getCookie('popup-cookie');

  if(!hasCookie) $(".cookies").slideToggle();
  $('.cookies__cta a').on('click', function() {
    $(".cookies").hide();
    setCookie('privacy-cookies', 'true', 1);
  });

  if(!hasPopupCookie) {
    $(".center-popup").delay(5000).queue(() => {
      openCenterPopup()
    })
  }
  $('#center-popup .mfp-close').on('click', function() {
    setCookie('popup-cookie', 'true', 1);
  })
  $('.center-popup__cta a').on('click', function() {
    setCookie('popup-cookie', 'true', 1);
    $.magnificPopup.close({
      items: { src: '#center-popup'}
    });
  })
}

export default function initCookies(){
  $(document).ready( init )
}
